export var FeatureFlagPrefix;
(function (FeatureFlagPrefix) {
    FeatureFlagPrefix["QueryParam"] = "qp:";
    FeatureFlagPrefix["FeaturesApi"] = "gbi:";
})(FeatureFlagPrefix || (FeatureFlagPrefix = {}));
export var FeatureFlag;
(function (FeatureFlag) {
    FeatureFlag["ConfigTypeNavigation"] = "enableConfigTypeNavigation";
    FeatureFlag["EmojiLang"] = "enableEmojiLang";
    FeatureFlag["UnverifiedTranslations"] = "enableUnverifiedTranslations";
    FeatureFlag["SingleTenantEnabled"] = "enableSingleTenant";
    FeatureFlag["SingleTenantName"] = "singleTenantName";
    FeatureFlag["Auth0"] = "enableAuth0";
    FeatureFlag["TrafficSplit"] = "enableTrafficSplit";
    FeatureFlag["DataQuality"] = "enableDataQuality";
    FeatureFlag["EnableHighlightMostRecentlyCreatedModel"] = "enableHighlightMostRecentlyCreated";
    FeatureFlag["EnableRecsModelsRedesign"] = "enableRecsModelsRedesign";
    FeatureFlag["EnablePipelineHealth"] = "enablePipelineHealth";
    FeatureFlag["EnablePinToTop"] = "enablePinToTop";
    FeatureFlag["EnableProductSearch"] = "enableProductSearch";
    FeatureFlag["EnableNewBiasing"] = "enableNewBiasing";
    FeatureFlag["EnableNewRulePromotion"] = "enableNewRulePromotion";
    FeatureFlag["enableBulkRulePromotion"] = "enableBulkRulePromotion";
    FeatureFlag["EnableReleaseNotes"] = "enableReleaseNotes";
    FeatureFlag["EnablePreviewStaticContent"] = "enablePreviewStaticContent";
    FeatureFlag["EnableRecallSearchFiltering"] = "enableRecallSearchFiltering";
    FeatureFlag["EnableCustomParameterPills"] = "enableCustomParameterPills";
    FeatureFlag["enableDynamicPreview"] = "enableDynamicPreview";
    FeatureFlag["enableNewButtonStyles"] = "enableNewButtonStyles";
    FeatureFlag["enableNewTableView"] = "enableNewTableView";
    FeatureFlag["EnableRecallGrouping"] = "enableRecallGrouping";
    FeatureFlag["enableNewBanner"] = "enableNewBanner";
    FeatureFlag["EnableAttributeFiltering"] = "enableAttributeFiltering";
    FeatureFlag["enableFontSize"] = "enableFontSize";
    FeatureFlag["enablePinnedProductsFiltering"] = "enablePinnedProductsFiltering";
    FeatureFlag["enableEvaluationToolDebug"] = "enableEvaluationToolDebug";
    // TODO: Clean it up after 'tileShowAttributesEnabled' feature flag will be irrelevant
    FeatureFlag["EnableTileShowAttributes"] = "enableTileShowAttributes";
    FeatureFlag["EnableNewSearchPerformanceDashboard"] = "enableNewSearchPerformanceDashboard";
})(FeatureFlag || (FeatureFlag = {}));
